import React, { useState, useRef, createRef } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import '../css/index-page.scss'

const usecases = ['Defense', 'Extreme Sports', 'Agriculture', 'Space Exploration', 'Remote Manufacturing']

export const IndexPageTemplate = ({
  landingTagline,
  intro,
  architecture,
  products
}) => {

  const usecaseRef = useRef()
  const productRefs = useRef(products.map(x => createRef()))
  const scrollToProduct = index => window.scrollTo(0, productRefs.current[index].current.offsetTop)

  const getImagePath = (image) => {
    if (image.publicURL) return image.publicURL
    else return image
  }

  const [usecase, setUsecase] = useState(0)

  const handleUsecaseVideoUpdate = e => {
    const checkAndSet = index => { if (usecase !== index) setUsecase(index) }
    const currentTime = e.target.currentTime + 0.1
    if (currentTime < 2.5 || currentTime >= 22.5) checkAndSet(0)
    else if (currentTime >= 2.5 && currentTime < 7.5) checkAndSet(1)
    else if (currentTime >= 7.5 && currentTime < 12.5) checkAndSet(2)
    else if (currentTime >= 12.5 && currentTime < 17.5) checkAndSet(3)
    else if (currentTime >= 17.5 && currentTime < 22.5) checkAndSet(4)
  }

  return (
    <>
      <div className='landing-container'>
        <video autoPlay muted loop className='bg-video'>
          <source src={'../video/Node Background Anim.mp4'} type="video/mp4" />
        </video>
        <img className='logo' src={'../img/Mente Logo Teal.svg'} alt='Mente' />
        <div className='navbar'>
          <button onClick={() => { scrollToProduct(0) }}><img src={'../img/Mente Zip Teal DrkGry.svg'} alt='MenteZIP' /></button>
          <button onClick={() => { scrollToProduct(1) }}><img src={'../img/Mente Edge Teal DrkGry.svg'} alt='MenteEDGE' /></button>
          <button onClick={() => { scrollToProduct(2) }}><img src={'../img/Mente Mesh Teal DrkGry.svg'} alt='MenteMESH' /></button>
        </div>
        <span className='tagline'>{landingTagline}</span>
        <ExpandMoreIcon className='scroll-down-icon' onClick={() => { window.scrollTo(0, usecaseRef.current.offsetTop) }} />
      </div>
      <div ref={usecaseRef} className='usecase-container'>
        <video autoPlay muted loop className='usecase-bg-video' onTimeUpdate={handleUsecaseVideoUpdate}>
          <source src={'../video/Mente Usecase Banner.mp4'} type="video/mp4" />
        </video>
        <div className='usecase-text-container'>
          <span>Open Edge AI for </span>
          <SwitchTransition>
            <CSSTransition key={usecase} classNames='fade' timeout={200}>
              <span>{usecases[usecase]}</span>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
      <div className='intro-container'>
        <div className='intro-header'>{intro.heading} </div>
        <div className='intro-blurbs-container'>
          {intro.blurbs.map((blurb, i) => (
            <div key={i} className='intro-blurb'>
              <h1>{blurb.heading}</h1>
              <span>{blurb.body}</span>
            </div>))}
        </div>
        <div className='intro-features-container'>
          {intro.features.map((feature, i) => (
            <div key={i} className='intro-feature'>
              <div className='feature-heading-container'>
                <span className='number'>{'0' + (i + 1)}</span>
                <span className='pipe'>{' | '}</span>
                <span className='heading'>{feature.heading}</span>
              </div>
              <span className='feature-body'>{feature.body}</span>
            </div>
          ))}
        </div>
      </div>
      <div className='architecture-container'>
        <span className='heading'>{architecture.heading}</span>
        <img className='diagram' src={getImagePath(architecture.diagram)} alt='Architecture Diagram'></img>
        <div className='product-list'>
          {products.map((product, i) => (
            <div key={i} className='product'>
              <img className='product-icon' src={getImagePath(product.icon)} alt={product.name} />
              <img className='product-name-icon' src={getImagePath(product.nameIcon)} alt={product.name} />
              <span className='description'>{product.productListDescription}</span>
              <button className='learn-more-button' onClick={() => { scrollToProduct(i) }}><span>Learn More</span></button>
            </div>
          ))}
        </div>
      </div>
      {products.map((product, i) => (
        <div key={i} ref={productRefs.current[i]} className='product-section'>
          <div className='heading-container'>
            <img className='icon' src={getImagePath(product.icon)} alt={product.name} />
            <img className='name-icon' src={getImagePath(product.nameIcon)} alt={product.name} />
          </div>
          <div className='blurb-container'>
            {product.blurbs.map((blurb, j) => (
              <div key={j} className='blurb'>
                <span className='bold'>{blurb.bold + ' '}</span>
                <span className='normal'>{blurb.normal}</span>
              </div>
            ))}
          </div>
          <div className='features-container'>
            {product.features.map((feature, j) => (
              <div key={j} className='feature'>
                <span className='heading'>{feature.heading}</span>
                <span className='body'>{feature.body}</span>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className='footer'>
        <div className='branding-container'>
          <img className='logo' src={'../img/Mente Logo White.svg'} alt='Mente' />
          <span>Bringing AI to the Edge</span>
        </div>
        <div className='text-container'>
          <div className='contact'>
            <h1>Contact Us</h1>
            <span>info@theinclab.com</span>
            <span>DC: +1 (212) 390-8111</span>
            <span>Tampa: +1 (813) 547-3559</span>
          </div>
          <div className='locations-container'>
            <div className='location'>
              <h1>Washington, DC</h1>
              <span>8300 Greensboro Dr.</span>
              <span>Suite 1040</span>
              <span>McLean, VA 22102</span>
            </div>
            <div className='location'>
              <h1>Tampa, FL</h1>
              <span>1320 East 9th Ave</span>
              <span>#110</span>
              <span>Tampa, FL, 33605</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}



IndexPageTemplate.propTypes = {
  landingTagline: PropTypes.string,
  intro: PropTypes.object,
  architecture: PropTypes.object,
  products: PropTypes.array
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        landingTagline={frontmatter.landingTagline}
        intro={frontmatter.intro}
        architecture={frontmatter.architecture}
        products={frontmatter.products}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        landingTagline
        intro {
          heading
          blurbs {
            heading
            body
          }
          features {
            heading
            body
          }
        }
        architecture {
          heading
          diagram {
            extension
            publicURL
          }
        }
        products {
          name
          icon {
            extension
              publicURL
          }
          nameIcon {
            extension
            publicURL
          }
          productListDescription
          blurbs {
            bold
            normal
          }
          features {
            heading
            body
          }
        }
      }
    }
  }
`